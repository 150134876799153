<template>
  <app>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <!-- Page Header: start -->
          <div v-if="false" class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <h2 class="
                  text-2xl
                  font-light
                  leading-7
                  text-gray-900
                  sm:text-2xl sm:leading-9 sm:truncate
                ">
                Top-Vorlagen
              </h2>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <!-- <span class="sm:ml-3 shadow-sm rounded-md">
                <Link :href="route('team.create')" class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    text-gray-500
                    bg-gray-300
                    shadow-sm
                    hover:bg-gray-400 hover:text-white
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-gray-400
                  ">Neuer User</Link>
              </span> -->
            </div>
          </div>
          <!-- Page Header: end -->

          <!-- <horizontal-divider /> -->

          <!-- TabNav: start -->
          <tab-nav class="mb-4"></tab-nav>
          <!-- TabNav: end -->

          <!-- Filter: start -->
          <div class="flex flex-row mb-4 w-full sm:w-1/2">
            <div class="mr-2 w-11/12">
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="
                    absolute
                    inset-y-0
                    left-0
                    pl-3
                    flex
                    items-center
                    pointer-events-none
                  ">
                  <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
                <input type="search" class="
                    mt-1
                    pl-10
                    focus:ring-gray-400 focus:border-gray-400
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  " placeholder="Suchen nach..." v-model="form.search" />
              </div>
            </div>

            <div class="w-1/12 text-center mt-3">
              <button v-if="isFiltered" v-tooltip.left="'Filter zurücksetzen'" @click="reset">
                <i class="fa fa-times fa-fw text-gray-500"></i>
              </button>

              <i v-if="!isFiltered" class="fa fa-times fa-fw text-gray-200"></i>
            </div>

          </div>
          <!-- Filter: end -->


          <!-- Empty state: start -->
          <div v-if="!stats.length" class="text-center w-full sm:w-1/2">
            <p class="mt-12 text-2xl font-light text-gray-500">Es liegt (noch) keine Statistik vor.</p>
          </div>
          <!-- Empty state: end -->


          

          <!-- List: start -->
          <div v-if="stats.length > 0" class="px-1 w-full sm:w-1/2">
            <div class="mt-8 flow-root">
              <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-7">
                  <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col"
                            class="w-5/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Vorlage</th>
                          
                          <th scope="col" class="w-2/12 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Nutzung
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="(item, idx) in stats" :key="idx">
                          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{
                            item.template_type }}: {{
                            item.template_name }}</td>
                          
                          <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                            {{ item.count }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- List: end -->
        </div>
      </div>
    </div>
  </app>
</template>

<script>
// import Layout from '@/Shared/Layout.vue';
import App from '@/Shared/App.vue';
import TabNav from '@/Pages/Team/Shared/TabNav.vue';
import { pickBy, debounce, mapValues, some, isNotEmpty } from 'lodash';

export default {
  metaInfo: { title: 'Top-Vorlagen' },

  components: {
    // Layout,
    App,
    TabNav,
  },

  props: {
    users: {
      type: Array,
      required: false,
      default: () => [],
    },

    stats: {
      type: Array,
      required: false,
      default: () => [],
    },

    filters: {
      type: Object,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        search: this.filters.search,
      },
    };
  },

  

  watch: {
    form: {
      handler: debounce(function () {
        let query = pickBy(this.form);
        this.$inertia.get(
          this.route(
            'team.stats.templates',
            Object.keys(query).length ? query : { remember: 'forget' }
          )
        );
      }, 250),
      deep: true,
    },
  },

  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
    },
  },

  computed: {
    isFiltered() {
      return some(this.form, isNotEmpty);
    },
  },
};
</script>
