<template>
  <div class="ml-3 relative">
    <div v-if="false">
      <button
      v-if="hasNotifications"
        @click="toggle"
        v-click-outside="hide"
        class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
      >
        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </button>


      <div
      v-if="!hasNotifications"
        class="p-1 border-2 border-transparent text-gray-400 rounded-full"
      >
        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </div>

      <span v-if="hasNotifications" class="absolute top-0 right-0 mr-1 mt-1 flex h-2 w-2">
        <span
          class="absolute inline-flex h-full w-full rounded-full bg-brandhelfer-red opacity-75"
          :class="isPulsating ? 'animate-ping' : 'animate-none'"
        ></span>
        <span class="relative inline-flex rounded-full h-2 w-2 bg-brandhelfer-red"></span>
      </span>
    </div>

    <!--
      Profile dropdown panel, show/hide based on dropdown state.

      Entering: "transition ease-out duration-200"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isVisible"
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50"
      >
        <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
          <div
          v-for="(notification, idx) in notifications"
          :key="idx"
          > 
            
            
            <div class="border-t border-gray-100"></div>
          </div>

          <div class="px-4 py-3">
            <button 
            @click="markAsRead"
            class="text-xs leading-5 text-left">
              Alle als gelesen markieren
            </button>
          </div>

          
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';


export default {
  directives: {
    ClickOutside    
  },

  props: {
    mode: {
      default: 'desktop',
    },
  },

  data() {
    return {
      isVisible: false,
      isPulsating: false,
      hasNotifications: this.$page.props.auth.user.notifications,
      notifications: Array
    };
  },

  mounted() {
    // Echo.private('App.User.' + this.$page.props.auth.user.id).notification(
    //   (notification) => {
        
    //   }
    // );
  },

  methods: {
    hide() {
      this.isVisible = false;
    },
    toggle() {
      if (this.isVisible) {
        this.isVisible = false;
      } else {
        this.isPulsating = false;
        this.isVisible = true;

        window.axios
          .get(
            route('users.notifications', {
              id: this.$page.props.auth.user.id,
            })
          )
          .then((response) => {
            this.notifications = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },

    markAsRead() {
      window.axios
        .post(
          route('users.notifications.mark-as-read', {
            id: this.$page.props.auth.user.id,
          })
        )
        .then(() => {
          this.isPulsating = false;
          this.isVisible = false;
          this.hasNotifications = false;
          this.notifications = [];
        })
        .catch(error => {
          
        });
    }
  },
};
</script>