
<div>
  <div class="grid grid-cols-1 sm:hidden">
    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
    <select v-model="selectedRoute" @change="navigateToRoute" aria-label="Select a tab"
      class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-brandhelfer-blue">
      <option v-for="(availableRoute, index) in availableRoutes" :key="index" :value="availableRoute.name"
        :selected="availableRoute.name == route().current(availableRoute.name)">
        {{ availableRoute.label }}
      </option>
    </select>
    <svg
      class="hidden pointer-events-none col-start-1 row-start-1 mr-2 w-5 h-5 self-center justify-self-end fill-gray-500"
      viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" data-slot="icon">
      <path fill-rule="evenodd"
        d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
        clip-rule="evenodd" />
    </svg>
  </div>
  <div class="hidden sm:block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <!-- Current: "border-brandhelfer-blue text-brandhelfer-blue", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
        <Link :href="route('team.stats.users')" class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          :class="route().current('team.stats.users') ? 'border-brandhelfer-blue text-brandhelfer-blue' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'">
        Top-User</Link>

        <Link :href="route('team.stats.templates')" class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          :class="route().current('team.stats.templates') ? 'border-brandhelfer-blue text-brandhelfer-blue' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'">
        Top-Vorlagen</Link>

        <Link :href="route('team.stats.usage')" class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          :class="route().current('team.stats.usage') ? 'border-brandhelfer-blue text-brandhelfer-blue' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'">
        Nutzung detailliert</Link>


        <Link :href="route('team.index')" class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          :class="route().current('team.index') ? 'border-brandhelfer-blue text-brandhelfer-blue' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
          aria-current="page">Userverwaltung</Link>

      </nav>
    </div>
  </div>
</div>

