<template>
  <component :is="layout">
    <slot></slot>
  </component>
</template>

<script>
export default {
  data() {
    return {
      layout: null
    };
  },

  async created() {
    const isSmallScreen = window.matchMedia('(max-width: 640px)').matches;

    if (isSmallScreen) {
      const module = await import('@/Shared/LayoutSmallscreen.vue');
      this.layout = module.default;
    } else {
      const module = await import('@/Shared/Layout.vue');
      this.layout = module.default;
    }
  }
};
</script>
