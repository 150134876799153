
<nav class="bg-white shadow-sm">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex items-center justify-between h-16">
      <div class="flex">
        <div class="flex-shrink-0 flex items-center">
          <Link :href="route('social-graphics.index')">
          <img class="block lg:hidden h-10 w-auto" src="/img/brandhelfer-logo.png" alt="Social Graphics" />
          <img class="hidden lg:block h-10 w-auto" src="/img/brandhelfer-logo.png" alt="Social Graphics" />
          </Link>
        </div>
        <div class="hidden sm:ml-6 sm:flex">

          <Link :href="route('social-graphics.index')"
            class="relative inline-flex items-center mr-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green">
          <svg class="-ml-1 mr-1 h-5 w-5" x-description="Heroicon name: plus" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"></path>
          </svg>
          <span>Neu</span>
          </Link>

          <Link :href="route('social-graphics.gallery')"
            class="inline-flex items-center mr-4 px-4 py-2 border border-gray-400  text-sm leading-5 font-medium rounded-md text-gray-500 hover:text-gray-500  hover:bg-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-400 active:bg-gray-400 transition duration-150 ease-in-out"
            :class="route().current('social-graphics.gallery') ? 'bg-gray-300' : 'bg-white'">
          Galerie</Link>


          <Link 
          v-if="$page.props.auth.user.can.manageTeam"
          :href="route('team.stats.users')"
            class="inline-flex items-center px-4 py-2 border border-gray-400  text-sm leading-5 font-medium rounded-md text-gray-500 hover:text-gray-500  hover:bg-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-400 active:bg-gray-400 transition duration-150 ease-in-out"
            :class="route().current('team.*') ? 'bg-gray-300' : 'bg-white'">
          User</Link>





          <Link v-if="false" :href="route('social-graphics.index')"
            class="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
            :class="route().current('social-graphics.*') ? 'active' : ''">Meine Social Graphics</Link>
        </div>
      </div>

      <search v-if="false"></search>

      <div class="hidden sm:ml-6 sm:flex sm:items-center">
        <div v-if="$page.props.auth.user.can.createSocialGraphics && false" class="flex-shrink-0">
          <Link :href="route('social-graphics.index')"
            class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green">
          <svg class="-ml-1 mr-2 h-5 w-5" x-description="Heroicon name: plus" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"></path>
          </svg>
          <span>Neu</span>
          </Link>
        </div>

        <!-- Notification desktop start -->
        <notification></notification>
        <!-- Notification desktop end -->

        <!-- Profile desktop start -->
        <profile></profile>
        <!-- Profile desktop end -->
      </div>

      <!-- Profile mobile start -->
      <div class="-mr-2 flex items-center sm:hidden">
        <!-- Mobile menu button -->
        <button
          class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
          <!-- Menu open: "hidden", Menu closed: "block" -->
          <svg class="h-6 w-6" :class="mobileMenuVisible ? 'hidden' : 'block'" @click="mobileMenuVisible = true"
            stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <!-- Menu open: "block", Menu closed: "hidden" -->
          <svg class="h-6 w-6" :class="mobileMenuVisible ? 'block' : 'hidden'" @click="mobileMenuVisible = false"
            stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <!-- Profile mobile end -->
    </div>
  </div>

  <!-- Mobile menu, toggle classes based on menu state.

    Open: "block", closed: "hidden"
  -->
  <div class="sm:hidden" :class="mobileMenuVisible ? 'block' : 'hidden'">
    <div class="pt-2">
      <!-- <Link
        :href="route('dashboard')"
        class="block pl-3 pr-4 py-2 border-l-4 border-red-500 text-base font-medium text-red-700 bg-red-50 focus:outline-none focus:text-red-800 focus:bg-red-100 focus:border-red-700 transition duration-150 ease-in-out"
        >Dashboard</Link
      > -->
      <Link :href="route('social-graphics.index')"
        class="mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out">
      Social Graphics</Link>
    </div>
    <div class="border-t border-gray-200" v-if="$page.props.auth.user.is.admin">
      <div role="menu">
        <Link v-if="$page.props.auth.user.is.admin" :href="route('partners.index')"
          class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out">
        Partner</Link>

        <Link v-if="$page.props.auth.user.is.admin" :href="route('users.index')"
          class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out">
        User</Link>

        <Link v-if="$page.props.auth.user.is.admin" :href="route('templates.index')"
          class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out">
        Vorlagen</Link>
      </div>
    </div>

    <div class="border-t border-gray-200">
      <div role="menu">
        <div class="px-4 py-3">
          <p class="text-sm leading-5">Eingeloggt als</p>
          <p class="text-sm leading-5 font-medium text-gray-900">
            {{ $page.props.auth.user.name }}
          </p>
        </div>

        <a href="#" @click="logout"
          class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out">Logout</a>
      </div>
    </div>
  </div>
</nav>
