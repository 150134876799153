<template>
  <layout>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <!-- Page Header: start -->
          <div class="md:flex md:items-center md:justify-between">
            <div v-if="false" class="flex-1 min-w-0">
              <h2 class="text-2xl font-light leading-7 text-gray-900 sm:text-2xl sm:leading-9 sm:truncate">
                Galerie
              </h2>
            </div>
            <div v-if="false" class="mt-4 flex md:mt-0 md:ml-4">
              <span class="sm:ml-3 shadow-sm rounded-md">
                <Link :href="route('social-graphics.index')"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-red-500 focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                  :disabled="!socialGraphicsTotal">Neue Social Graphic anlegen</Link>
              </span>
            </div>
          </div>
          <!-- Page Header: end -->

          <horizontal-divider v-if="false && socialGraphicsTotal" />

          
          <!-- Filter: start -->
          <div class="flex flex-col sm:flex-row mb-4">
            <div class="mr-2 sm:w-5/12">
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
                <input type="search"
                  class="mt-1 pl-10 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Suchen nach..." v-model="form.search" />
              </div>
            </div>


            <div 
            v-if="partnerUsers.length > 0"
            class="w-12/12 sm:w-3/12 mr-2">
              <select v-model="form.partner_user_id"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm">
                <option value="all">Alle User</option>
                <option v-for="partnerUser in partnerUsers" :value="partnerUser.id" :key="partnerUser.id">
                  {{ partnerUser.name }}
                </option>
              </select>
            </div>


            <div class="sm:w-3/12 flex">
              <div class="w-10/12 sm:w-11/12 mr-2">
                <select v-model="form.template_type_id"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm">
                  <option :value="null">Alle Arten</option>
                  <option v-for="templateType in templateTypes" :value="templateType.id" :key="templateType.id">
                    {{ templateType.name }}
                  </option>
                </select>
              </div>

              <div class="w-2/12 sm:w-1/12 text-center mt-3">
                <button v-if="isFiltered" v-tooltip.left="'Filter zurücksetzen'" @click="reset">
                  <i class="fa fa-times fa-fw text-gray-500"></i>
                </button>

                <i v-if="!isFiltered" class="fa fa-times fa-fw text-gray-200"></i>
              </div>
            </div>
          </div>
          <!-- Filter: end -->

          <!-- List: start -->

          <div v-masonry transition-duration="0.3s" item-selector=".item"
            v-lazy-container="{ selector: 'img.masonry' }">

            <div v-masonry-tile class="item w-64 mr-5 mb-5 relative rounded-md flex flex-col"
              v-for="socialGraphic in socialGraphics" :key="socialGraphic.id">
              <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                <div class="px-0 py-0">
                  <spinner :busy="loading" color="#000"></spinner>

                  <a :href="route('social-graphics.download', { id: socialGraphic.id })">
                    <img v-bind:data-src="socialGraphic.thumbnail_link" class="rounded-t-lg masonry"
                      :style="`height: ${socialGraphic.thumbnail_height}px;`"
                      :data-original-height="socialGraphic.thumbnail_height" @load="adjustImageHeight" />
                  </a>
                </div>
                <div
                  class="w-full px-2 py-2 flex flex-col space-y-3 items-start justify-between space-between group/footer">
                  <div class="flex-1">
                    <p class="text-xs">
                      {{ $filters.formatDateTime(socialGraphic.created_at) }}
                    </p>
                    <p v-if="socialGraphic.user" class="text-xs">
                      {{ socialGraphic.user.name }}
                    </p>
                  </div>
                  <div class="w-full">
                    <div class="w-full flex items-center justify-between">
                      <div class="flex-1"><svg @click.stop.prevent="deleteSocialGraphic(socialGraphic)"
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor"
                          class="w-4 h-4 text-brandhelfer-red outline-none hidden group-hover/footer:block cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg></div>

                      <div class="flex items-center space-x-3">


                        <a v-if="hasTransparentPng(socialGraphic)"
                          :href.stop="route('social-graphics.download-png', { id: socialGraphic.id })"
                          class="flex items-center space-x-1 group">

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5 text-gray-500 outline-none group-hover:text-brandhelfer-blue">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                          </svg>

                          <p class="text-xs group-hover:text-brandhelfer-blue">PNG</p>

                        </a>

                        <a :href="route('social-graphics.download', { id: socialGraphic.id })"
                          class="flex items-center space-x-1 group">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5 text-gray-500 outline-none group-hover:text-brandhelfer-blue">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                          </svg>

                          <p class="text-xs group-hover:text-brandhelfer-blue">JPG</p>
                        </a>


                        <!-- <a v-if="canGeneratePdf"
                          :href="route('social-graphics.generate-pdf', { id: socialGraphic.id, format: 'gs' })"
                          target="_blank">
                          <i class="fa fa-fw fa-xl fa-file-pdf-o text-gray-400"></i>
                        </a>


                        <a v-if="canGeneratePdf"
                          :href="route('social-graphics.generate-pdf', { id: socialGraphic.id, format: 'im' })"
                          target="_blank">
                          <i class="fa fa-fw fa-xl fa-file-pdf-o text-gray-400"></i>
                        </a> -->

                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>




          </div>

          <div v-if="message" class="flex h-64">
            <div class="m-auto">
              <p class="text-2xl font-extralight text-gray-400">
                {{ message }}
              </p>
            </div>
          </div>
          <!-- List: end -->
        </div>
      </div>
    </div>

    <confirm-modal v-if="confirmModal.visible" :headline="confirmModal.headline" :text="confirmModal.text"
      @close="confirmModal.visible = false" @confirm="deleteSocialGraphicConfirmed"></confirm-modal>
  </layout>
</template>


<script>
import Layout from '@/Shared/Layout.vue';

import Spinner from '@/Shared/Spinner.vue';
// TODO: extract the confirmation modal to a decoupled component
import ConfirmModal from '@/Shared/ConfirmModal.vue';
import { pickBy, debounce, mapValues, some, isNotEmpty } from 'lodash';

export default {
  metaInfo: { title: '' },

  components: {
    Layout,

    Spinner,
    ConfirmModal,
  },

  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },

    socialGraphics: {
      type: Array,
      required: false, 
      default: () => [],
    },

    socialGraphicsTotal: {
      type: Number,
      required: false,
      default: 0,
    },

    templateTypes: {
      type: Array,
      required: false, 
      default: () => [],
    },

    partnerUsers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      confirmModal: {
        visible: false,
        itemId: null,
        headline: 'Social Graphic löschen',
        text: '',
      },
      form: {
        partner_user_id: this.filters.partner_user_id || this.$page.props.auth.user.id,
        template_type_id: this.filters.template_type_id,
        search: this.filters.search,
      },
      revealInfo: null,
    };
  },


  watch: {
    form: {
      handler: debounce(function () {
        let query = pickBy(this.form);
        // console.log(query);
        this.$inertia.get(
          this.route(
            'social-graphics.gallery',
            Object.keys(query).length ? query : { remember: 'forget' }
          )
        );
      }, 250),
      deep: true,
    },
  },

  methods: {
    // TODO: this is just a quick & dirty way to adjust the heights of the images
    // after the widths have been modified by Masonry.
    // Maybe there is a better way or something like VueMasonryWall
    adjustImageHeight(event) {
      let element = event.target;
      // console.log(element);
      let originalHeight = element.getAttribute('data-original-height');

      let height = originalHeight * (element.width / 360);
      element.style.height = height + 'px';

      this.loading = false;
    },

    hasTransparentPng(socialGraphic) {
      if (!socialGraphic.has_transparent_png) return false;

      return true;
    },

    reset() {
      this.form = mapValues(this.form, () => null);
    },

    deleteSocialGraphic(socialGraphic) {
      this.confirmModal.itemId = socialGraphic.id;
      this.confirmModal.text = `Soll diese Social Graphic wirklich gelöscht werden?`;
      this.confirmModal.visible = true;
    },

    deleteSocialGraphicConfirmed() {
      if (!this.confirmModal.itemId) return;

      this.sending = true;

      this.$inertia.delete(
        route('social-graphics.delete', {
          id: this.confirmModal.itemId,
        }),
        {
          preserveScroll: true,
          onSuccess: (page) => {
            this.sending = false;
            this.confirmModal.visible = false;
          },
        }
      );

    },

    // generatePdf(socialGraphic) {
    //   console.log(socialGraphic);
    // },
  },

  computed: {
    isFiltered() {
      return some(this.form, isNotEmpty);
    },

    message() {
      if (!this.$page.props.auth.user.can.createSocialGraphics) {
        return 'Ihnen wurden noch keine Vorlagen zugeordnet. Sie können noch keine Social Graphics anlegen.';
      }

      if (
        this.$page.props.auth.user.can.createSocialGraphics &&
        this.socialGraphicsTotal === 0
      ) {
        if(this.$page.props.auth.user.is.account_manager) {
          return 'Zu Ihrem Filter liegen (noch) keine Social Graphics vor.';  
        }

        return 'Legen Sie Ihre erste Social Graphic an.';
      }

      if (this.socialGraphicsTotal > 0 && this.socialGraphics.length === 0) {
        return 'Für Ihre Auswahl liegen noch keine Social Graphics vor.';
      }

      return '';
    },



    canGeneratePdf() {
      return false;
      return this.$page.props.auth.user.email.includes('@brandhelfer.de');
    },
  },
};
</script>
