<template>
  <layout-smallscreen active-menu-item="new">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div
          class="-my-2 py-2 overflow-x-auto overflow-y-hidden sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <!-- Page Header: start -->
          <div  v-if="false" class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <div class="-ml-2 -mt-2 flex flex-col flex-wrap items-baseline">
                <h2
                  class="ml-2 mt-2 text-2xl leading-7 font-light text-gray-900"
                >
                Neue Social Graphic anlegen
                </h2>
                <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
                  Vorlage auswählen
                </p>
              </div>
            </div>
            
          </div>
          <!-- Page Header: end -->

          <horizontal-divider :padding="2" />

          <ul class="grid grid-cols-3 gap-2 sm:grid-cols-2 lg:grid-cols-6">
            <li
              class="relative col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 hover:bg-gray-200 cursor-pointer"
              :class="!selectedTemplateType ? 'bg-gray-200' : ''"
              @click="selectedTemplateType = null"
            >
              <div class="w-full flex items-center justify-between p-3">
                <h3 class="text-gray-900 text-sm font-medium truncate">
                  Alle
                </h3>
              </div>
            </li>

            <li
              v-for="template_type in template_types"
              :key="template_type.id"
              class="relative col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 hover:bg-gray-200 cursor-pointer"
              :class="selectedTemplateType == template_type.id ? 'bg-gray-200' : ''"
              @click="selectedTemplateType = template_type.id"
            >
              <div class="w-full flex items-center justify-between p-3">
                <div class="flex-1 truncate">
                  <div class="flex items-center space-x-3">
                    <h3 class="text-gray-900 text-sm font-medium truncate">
                      {{ template_type.name }}
                    </h3>
                  </div>
                  
                </div>
              </div>
            </li>
          </ul>

          <div v-masonry transition-duration="0.3s" item-selector=".item" v-lazy-container="{ selector: 'img.masonry' }"
            class="mt-4">
            <div v-for="template in filteredTemplates" :key="template.id" v-masonry-tile
              class="item flex flex-col w-[47%] rounded-t-md mr-2 mb-6">
              <a :href="route('social-graphics.create', { template: template })" class="block w-full relative">
                <spinner :busy="loading" color="#000"></spinner>


                <img :data-src="route('social-graphics.preview-image', { template: template })
                  " class="rounded-t-md masonry" :style="`height: ${template.preview_image_height}px;`"
                  :data-original-height="template.preview_image_height" @load="adjustImageHeight" />
              </a>
              <div class="mt-1 px-2 py-1 flex flex-col space-y-2">
                <p class="text-xs">{{ template.template_type.name }} | {{ template.template_type.width }}x{{
                  template.template_type.height
                }}<br />{{ template.name }}</p>

                <div class="border border-t border-gray-200"></div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </layout-smallscreen>
</template>

<script>
import LayoutSmallscreen from '@/Shared/LayoutSmallscreen.vue';

import Spinner from '@/Shared/Spinner.vue';
import SubmitButton from '@/Shared/SubmitButton.vue';
 
export default {
  metaInfo: { title: 'Vorlagenauswahl' },

  components: {
    LayoutSmallscreen,
    
    Spinner,
    SubmitButton,
  },

  props: {
    template_types: Array,
    templates: Array,
  },

  data() {
    return {
      loading: false,
      sending: false,
      selectedTemplateType: null,
      selectedTemplateIdx: 0,
      revealInfo: null,
    };
  },

  

  methods: {
    

    // TODO: this is just a quick & dirty way to adjust the heights of the images
    // after the widths have been modified by Masonry.
    // Maybe there is a better way or something like VueMasonryWall
    adjustImageHeight(event) {
      let element = event.target;
      console.log(element);
      let originalHeight = element.getAttribute('data-original-height');

      let height = originalHeight * (element.width / 360);
      element.style.height = height + 'px';

      this.loading = false;
    },

    //   submit() {

    //     if(this.templates[this.selectedTemplateIdx] === undefined) {
    //       return false;
    //     }

    //     this.sending = true;

    //     this.$inertia
    //       .visit(
    //         route('social-graphics.create', {
    //           template: this.templates[this.selectedTemplateIdx],
    //         }),
    //         {}
    //       )
    //       .then(() => {
    //         this.sending = false;
    //       });
    //   },
  },

  computed: {
    filteredTemplates() {
      if(!this.selectedTemplateType) {
        return this.templates;
      }

      return this.templates.filter((template) => {
        return template.template_type.id == this.selectedTemplateType;
      })
    }

    
  },
};
</script>