
<app>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <!-- Page Header: start -->
        <div v-if="false" class="md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <h2 class="
                text-2xl
                font-light
                leading-7
                text-gray-900
                sm:text-2xl sm:leading-9 sm:truncate
              ">
              User
            </h2>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">
            <span class="sm:ml-3 shadow-sm rounded-md">
              <Link :href="route('team.create')" class="
                  inline-flex
                  items-center
                  px-4
                  py-2
                  border border-transparent
                  text-sm
                  font-medium
                  rounded-md
                  text-gray-500
                  bg-gray-300
                  shadow-sm
                  hover:bg-gray-400 hover:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-gray-400
                ">Neuer User</Link>
            </span>
          </div>
        </div>
        <!-- Page Header: end -->

        <!-- <horizontal-divider /> -->

        <!-- TabNav: start -->
        <tab-nav class="mb-4"></tab-nav>
        <!-- TabNav: end -->

        <!-- Filter: start -->
        <div class="flex flex-row mb-4 w-full sm:w-2/3">
          <div class="mr-0 sm:mr-2 w-9/12">
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="
                  absolute
                  inset-y-0
                  left-0
                  pl-3
                  flex
                  items-center
                  pointer-events-none
                ">
                <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd" />
                </svg>
              </div>
              <input type="search" class="
                  mt-1
                  pl-10
                  focus:ring-gray-400 focus:border-gray-400
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                " placeholder="Suchen nach..." v-model="form.search" />
            </div>
          </div>

          <div class="w-1/12 text-center mt-3">
            <button v-if="isFiltered" v-tooltip.left="'Filter zurücksetzen'" @click="reset">
              <i class="fa fa-times fa-fw text-gray-500"></i>
            </button>

            <i v-if="!isFiltered" class="fa fa-times fa-fw text-gray-200"></i>
          </div>

          <div class="w-2/12 text-center mt-1">

            <span class="sm:ml-3 shadow-sm rounded-md">
              <Link :href="route('team.create')"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-500 bg-gray-300 shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
              <span class="hidden sm:inline-block">Neuer User</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="block sm:hidden w-5 h-5" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>

              </Link>
            </span>
          </div>

        </div>
        <!-- Filter: end -->


        <!-- Empty state: start -->
        <div v-if="!users.length" class="text-center w-full sm:w-2/3">
          <p class="mt-12 text-2xl font-light text-gray-500">Es liegen (noch) keine User vor.</p>
        </div>
        <!-- Empty state: end -->




        <!-- List: start -->
        <div v-if="users.length > 0" class="px-1 w-full sm:w-3/4">
          <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-7">
                <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col"
                          class="w-4/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name
                        </th>

                        <th scope="col" class="hidden sm:block w-4/12 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          E-Mail</th>

                        <th scope="col" class="w-1/12 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Vorlagen</th>

                        <th scope="col"
                          class="w-1/12 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 whitespace-nowrap">
                          <span class="hidden sm:block">Social Graphics</span>
                          <span class="block sm:hidden">SG</span></th>

                        <th scope="col" class="w-2/12 px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                      <tr v-for="(user, idx) in users" :key="user.id">
                        <td
                          class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 hover:text-brandhelfer-blue">
                          <Link :href="route('team.edit', { id: user.id })">{{
                            user.name }}</Link>
                        </td>

                        <td class="hidden sm:block whitespace-nowrap px-3 py-4 text-sm text-gray-500 hover:text-brandhelfer-blue">
                          <Link :href="route('team.edit', { id: user.id })">{{
                            user.email }}</Link>
                        </td>

                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center hover:text-brandhelfer-blue">
                          <Link :href="route('team.edit', { id: user.id })">{{
                            user.templates_count || 0 }}</Link>
                        </td>

                        <td
                          class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center hover:text-brandhelfer-blue">
                          <Link :href="route('social-graphics.gallery', { partner_user_id: user.id })">{{
                            user.social_graphics_count || 0 }}</Link>
                        </td>

                        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">


                          <div class="flex items-center justify-end space-x-3">

                            <Link :href="route('team.edit', { id: user.id })">
                            <svg xmlns="http://www.w3.org/2000/svg"
                              class="w-5 h-5 text-gray-400 hover:text-brandhelfer-blue" viewBox="0 0 256 256">
                              <rect width="256" height="256" fill="none" />
                              <path
                                d="M92.69,216H48a8,8,0,0,1-8-8V163.31a8,8,0,0,1,2.34-5.65L165.66,34.34a8,8,0,0,1,11.31,0L221.66,79a8,8,0,0,1,0,11.31L98.34,213.66A8,8,0,0,1,92.69,216Z"
                                fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="16" />
                              <line x1="136" y1="64" x2="192" y2="120" fill="none" stroke="currentColor"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="16" />
                            </svg>
                            </Link>


                            <Link :href="route('social-graphics.gallery', { partner_user_id: user.id })"
                              v-tooltip.left="'Zur Social Graphics Galerie von ' + user.name">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"
                              class="w-5 h-5 text-gray-400 hover:text-brandhelfer-blue">
                              <rect width="256" height="256" fill="none" />
                              <rect x="64" y="48" width="160" height="128" rx="8" fill="none" stroke="currentColor"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="16" />
                              <circle cx="172" cy="84" r="12" />
                              <path
                                d="M64,128.69l38.34-38.35a8,8,0,0,1,11.32,0L163.31,140,189,114.34a8,8,0,0,1,11.31,0L224,138.06"
                                fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="16" />
                              <path d="M192,176v24a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V88a8,8,0,0,1,8-8H64" fill="none"
                                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="16" />
                            </svg>
                            </Link>

                            <button v-if="!user.role_names.includes('admin')" class="
                              text-gray-400
                              focus:outline-none
                               font-normal
                               ml-3
                            " @click.stop.prevent="deleteUser(user)">
                              <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400 hover:text-red-500"
                                viewBox="0 0 256 256">
                                <rect width="256" height="256" fill="none" />
                                <line x1="216" y1="56" x2="40" y2="56" fill="none" stroke="currentColor"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="16" />
                                <line x1="104" y1="104" x2="104" y2="168" fill="none" stroke="currentColor"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="16" />
                                <line x1="152" y1="104" x2="152" y2="168" fill="none" stroke="currentColor"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="16" />
                                <path d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56" fill="none"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="16" />
                                <path d="M168,56V40a16,16,0,0,0-16-16H104A16,16,0,0,0,88,40V56" fill="none"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="16" />
                              </svg>
                            </button>
                          </div>



                          <!-- <div class="flex flex-col items-start space-y-3">
                            <Link :href="route('social-graphics.gallery', { partner_user_id: user.id })"
                              class="flex items-center space-x-2 group">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"
                              class="w-5 h-5 text-gray-400 group-hover:text-brandhelfer-blue">
                              <rect width="256" height="256" fill="none" />
                              <rect x="64" y="48" width="160" height="128" rx="8" fill="none" stroke="currentColor"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="16" />
                              <circle cx="172" cy="84" r="12" />
                              <path
                                d="M64,128.69l38.34-38.35a8,8,0,0,1,11.32,0L163.31,140,189,114.34a8,8,0,0,1,11.31,0L224,138.06"
                                fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="16" />
                              <path d="M192,176v24a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V88a8,8,0,0,1,8-8H64" fill="none"
                                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="16" />
                            </svg>
                            <p class="text-gray-400 font-normal group-hover:text-brandhelfer-blue">Zur Social Graphics Galerie</p>
                            </Link>

                            <button v-if="!user.role_names.includes('admin')" class="
                              
                              text-gray-400
                              focus:outline-none
                              hover:text-red-500
                               font-normal
                               flex items-center space-x-2
                            " @click.stop.prevent="deleteUser(user)">
                              <i class="fa fa-trash fa-fw"></i>
                              <p>User löschen</p>
                            </button>
                          </div> -->


                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- List: end -->
      </div>
    </div>
  </div>

  <confirm-modal v-if="confirmModal.visible" :headline="confirmModal.headline" :text="confirmModal.text"
    @close="confirmModal.visible = false" @confirm="deleteUserConfirmed"></confirm-modal>
</app>
