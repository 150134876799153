<template>
  <app>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <!-- Page Header: start -->
          <div v-if="false" class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <h2 class="
                  text-2xl
                  font-light
                  leading-7
                  text-gray-900
                  sm:text-2xl sm:leading-9 sm:truncate
                ">
                Nutzung detailliert
              </h2>
            </div>
            <div class="mt-4 flex md:mt-0 md:ml-4">
              <!-- <span class="sm:ml-3 shadow-sm rounded-md">
                <Link :href="route('team.create')" class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    text-gray-500
                    bg-gray-300
                    shadow-sm
                    hover:bg-gray-400 hover:text-white
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-gray-400
                  ">Neuer User</Link>
              </span> -->
            </div>
          </div>
          <!-- Page Header: end -->

          <!-- <horizontal-divider /> -->

          <!-- TabNav: start -->
          <tab-nav class="mb-4"></tab-nav>
          <!-- TabNav: end -->

          <!-- Filter: start -->
          <div class="flex flex-row mb-4 w-full sm:w-1/2">
            <div class="mr-2 w-11/12">
              <div class="mt-1 relative rounded-md shadow-sm">
                <div class="
                    absolute
                    inset-y-0
                    left-0
                    pl-3
                    flex
                    items-center
                    pointer-events-none
                  ">
                  <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
                <input type="search" class="
                    mt-1
                    pl-10
                    focus:ring-gray-400 focus:border-gray-400
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  " placeholder="Suchen nach..." v-model="form.search" />
              </div>
            </div>

            <div class="w-1/12 text-center mt-3">
              <button v-if="isFiltered" v-tooltip.left="'Filter zurücksetzen'" @click="reset">
                <i class="fa fa-times fa-fw text-gray-500"></i>
              </button>

              <i v-if="!isFiltered" class="fa fa-times fa-fw text-gray-200"></i>
            </div>

          </div>
          <!-- Filter: end -->


          <!-- Date range: start -->
          <!-- <div class="mb-4 w-fit">

            <vc-date-picker v-model="range" mode="date" :masks="masks" is-range>
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="flex flex-col sm:flex-row justify-start items-center">
                  <div class="relative flex-grow">
                    <svg class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none" fill="none"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z">
                      </path>
                    </svg>
                    <input class="flex-grow pl-8 pr-2 py-1  focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start"
                      v-on="inputEvents.start" />
                  </div>
                  <span class="flex-shrink-0 m-2">
                    <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </span>
                  <div class="relative flex-grow">
                    <svg class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none" fill="none"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z">
                      </path>
                    </svg>
                    <input class="flex-grow pl-8 pr-2 py-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end"
                      v-on="inputEvents.end" />
                  </div>
                </div>
              </template>
            </vc-date-picker>
          </div> -->
          <!-- Date range: end -->


          <!-- Empty state: start -->
          <div v-if="!stats.length" class="text-center w-full sm:w-1/2">
            <p class="mt-12 text-2xl font-light text-gray-500">Es liegt (noch) keine Statistik vor.</p>
          </div>
          <!-- Empty state: end -->




          <!-- List: start -->
          <div v-if="stats.length > 0" class="px-1 w-full sm:w-2/3">
            <div class="mt-8 flow-root">
              <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col"
                            class="w-4/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            User
                          </th>

                          <th scope="col"
                            class="w-5/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Template</th>


                          <th scope="col" class="w-2/12 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            Nutzung
                          </th>

                          <th scope="col" class="w-1/12 px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody v-for="(user, idx) in stats" :key="user.id" class="bg-white">
                        <!-- divide-y divide-gray-200  -->
                        <tr v-for="(item, idx2) in user.templates" :key="idx2">
                          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <Link v-if="!idx2"
                              :href="route('social-graphics.gallery', { partner_user_id: item.user_id })"
                              class="hover:text-brandhelfer-blue">{{
                                user.user_name }}</Link>
                          </td>

                          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{
                            item.template_type }}: {{
                              item.template_name }}</td>

                          <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                            {{ item.count }}
                          </td>

                          <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">


                            <div class="flex items-center justify-end space-x-3">

                              <Link :href="route('social-graphics.gallery', { partner_user_id: item.user_id })"
                                v-tooltip.left="'Zur Social Graphics Galerie von ' + item.user_name">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"
                                class="w-5 h-5 text-gray-400 hover:text-brandhelfer-blue">
                                <rect width="256" height="256" fill="none" />
                                <rect x="64" y="48" width="160" height="128" rx="8" fill="none" stroke="currentColor"
                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="16" />
                                <circle cx="172" cy="84" r="12" />
                                <path
                                  d="M64,128.69l38.34-38.35a8,8,0,0,1,11.32,0L163.31,140,189,114.34a8,8,0,0,1,11.31,0L224,138.06"
                                  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="16" />
                                <path d="M192,176v24a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V88a8,8,0,0,1,8-8H64" fill="none"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                  stroke-width="16" />
                              </svg>
                              </Link>

                            </div>



                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- List: end -->
        </div>
      </div>
    </div>
  </app>
</template>


<script>
// import Layout from '@/Shared/Layout.vue';
import App from '@/Shared/App.vue';
import TabNav from '@/Pages/Team/Shared/TabNav.vue';
import { pickBy, debounce, mapValues, some, isNotEmpty } from 'lodash';

export default {
  metaInfo: { title: 'Nutzung detailliert' },

  components: {
    // Layout,
    App,
    TabNav,
  },

  props: {
    users: {
      type: Array,
      required: false,
      default: () => [],
    },

    stats: {
      type: Array,
      required: false,
      default: () => [],
    },

    filters: {
      type: Object,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      range: {
        start: dayjs().startOf('week').format('YYYY-MM-DD'),
        end: dayjs().endOf('week').format('YYYY-MM-DD'),
      },
      masks: {
        input: 'DD.MM.YYYY',
      },
      form: {
        search: this.filters.search,
        from: null, 
        to: null,   
      },
    };
  },

  watch: {
    range: {
      handler(newRange) {
        if (newRange.start && newRange.end) {
          this.form.from = newRange.start;
          this.form.to = newRange.end;
        }
      },
      immediate: true,
      deep: true,
    },

    form: {
      handler: debounce(function () {
        let query = pickBy(this.form);
        this.$inertia.get(
          this.route(
            'team.stats.usage',
            Object.keys(query).length ? query : { remember: 'forget' }
          )
        );
      }, 250),
      deep: true,
    },
  },

  methods: {
    reset() {
      this.form = mapValues(this.form, () => null);
      this.range = {
        start: dayjs().startOf('week').format('YYYY-MM-DD'),
        end: dayjs().endOf('week').format('YYYY-MM-DD'),
      };
    },
  },

  computed: {
    isFiltered() {
      return some(this.form, isNotEmpty);
    },
  },
};
</script>