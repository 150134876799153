<template>
  <blank-layout>
    <div class="min-h-screen bg-gray-50 flex flex-col py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-12 w-auto" src="/img/brandhelfer-logo.png" alt="Social Graphics" />
        <h2 class="mt-6 text-center text-3xl leading-9 font-light text-gray-900">Login</h2>
      </div>

      <div class="mt-3 w-11/12 mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form @submit.prevent="submit" novalidate>
            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-5 text-gray-700"
              >E-Mail Adresse</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  type="email"
                  required
                  class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                  v-model="form.email"
                  :disabled="sending"
                />
              </div>
            </div>

            <div class="mt-6">
              <label
                for="password"
                class="block text-sm font-medium leading-5 text-gray-700"
              >Password</label>
              <div class="mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  type="password"
                  required
                  class="mt-1 focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md"
                  v-model="form.password"
                  :disabled="sending"
                />
              </div>
            </div>

            <div class="mt-6 flex items-center justify-between">
              <!-- <div class="flex items-center">
                <input
                  id="remember_me"
                  type="checkbox"
                  class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out"
                />
                <label
                  for="remember_me"
                  class="ml-2 block text-sm leading-5 text-gray-900"
                >Remember me</label>
              </div> -->

              <div class="text-sm leading-5">
                <Link
                  href="/password/reset"
                  class="font-medium text-red-600 hover:text-red-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >Passwort vergessen?</Link>
              </div>
            </div>

            <div class="mt-6">
              <submit-button :sending="sending" :validated="validated">Login</submit-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </blank-layout>
</template>

<script>
import BlankLayout from "@/Shared/BlankLayout.vue";
import SubmitButton from "@/Shared/SubmitButton.vue";

export default {
  metaInfo: { title: 'Login' },

  components: {
    BlankLayout,
    SubmitButton
  },
  data() {
    return {
      sending: false,

      form: this.$inertia.form({
        email: "",
        password: "",
      }),

      // form: {
      //   email: "",
      //   password: ""
      // }
    };
  },

  methods: {
    submit() {
      this.sending = true;

      // this.$inertia.post("/login", this.form).then(() => {
        // if (this.$page.props.first_error) {
        //   this.$toasted.error(this.$page.props.first_error);
        // }
      //   this.sending = false;
      // });

      this.form.post('/login', {
        preserveScroll: true,
        onSuccess: () => {
          this.sending = false;
        },
        onError: (errors) => {
          this.sending = false;
        },
      });
    }
  },

  computed: {
    validated() {
      return this.form.email.length && this.form.password.length;
    }
  }
};
</script>
