<template>
  <footer class="w-full p-2 flex items-center space-x-3">


    <!-- <div>
  
    <nav class="flex space-x-4" aria-label="Tabs">
      <Link href="#" class="rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-700">My Account</Link>
      <Link href="#" class="rounded-md px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-700">Company</Link>
      <Link href="#" class="rounded-md bg-indigo-100 px-3 py-2 text-sm font-medium text-indigo-700" aria-current="page">Team Members</Link>
    </nav>
  
</div> -->




    <Link :href="route('social-graphics.index')"
      class="group rounded-md flex items-center space-x-2 text-center justify-center py-2 px-1 border-2 font-medium text-sm w-1/3 focus:outline-none bg-brandhelfer-green text-white"
      >
    <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <span>Neu</span>
    </Link>


    <Link :href="route('social-graphics.gallery')"
      class="group rounded-md flex items-center space-x-2 text-center justify-center py-2 px-1 border-2 font-medium text-sm w-1/3 focus:outline-none "
      :class="route().current('social-graphics.gallery') && !moreMenuExpanded
        ? 'bg-gray-400'
        : 'bg-gray-300'
      "
      >
    <svg class="h-5 w-5" :class="route().current('social-graphics.gallery') && !moreMenuExpanded
        ? 'text-white'
        : 'text-gray-500'
      " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
    </svg>

    <span :class="route().current('social-graphics.gallery') && !moreMenuExpanded
        ? 'text-white'
        : 'text-gray-500'
      ">Galerie</span>
    </Link>


    <Link 
    v-if="$page.props.auth.user.is.account_manager"
    :href="route('team.stats.users')"
      class="group rounded-md flex items-center space-x-2 text-center justify-center py-2 px-1 border-2 font-medium text-sm w-1/3 focus:outline-none"
      :class="route().current('team.*') && !moreMenuExpanded
        ? 'bg-gray-400'
        : 'bg-gray-300'
      "
      >

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5" :class="route().current('team.*') && !moreMenuExpanded
        ? 'text-white'
        : 'text-gray-500'
      ">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
    </svg>

    <span :class="route().current('team.*') && !moreMenuExpanded
        ? 'text-white'
        : 'text-gray-500'
      ">User</span>
    </Link>




    <div v-if="!$page.props.auth.user.is.account_manager"
    class="w-1/3"
    >
    <button
      type="button"
      @click="moreMenuExpanded = !moreMenuExpanded"
      class="group rounded-md flex items-center space-x-2 text-center justify-center py-2 px-1 border-2 font-medium text-sm w-full focus:outline-none"
      :class="moreMenuExpanded
        ? 'bg-gray-400'
        : 'bg-gray-300'
      "
    >
      <svg
        class="h-5 w-5"
        :class="moreMenuExpanded
        ? 'text-white'
        : 'text-gray-500'
      "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>

      <span :class="moreMenuExpanded
        ? 'text-white'
        : 'text-gray-500'
      ">Mehr</span>
    </button>

    

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="moreMenuExpanded"
        class="origin-top-right z-10 absolute right-2 top-14 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >

      
        <div class="py-1">
          <Link
            class="block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            v-if="$page.props.auth.user.can.manageTeam"
            :href="route('team.stats.users')"
            >User</Link
          >


          <Link
            class="block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            v-if="$page.props.auth.user.is.admin"
            :href="route('partners.index')"
            >Partner</Link
          >

          <Link
            v-if="$page.props.auth.user.is.admin"
            :href="route('users.index')"
            class="block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            >User</Link
          >

          <Link
            v-if="$page.props.auth.user.is.admin"
            :href="route('templates.index')"
            class="block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            >Vorlagen</Link
          >
        </div>



        <div class="py-1">
          <a
            href="#"
            @click="logout"
            class="block text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            >Logout</a
          >
        </div>
      </div>
    </transition>
    </div>



  </footer>
</template>

<script>
import Profile from '@/Shared/Header/Profile.vue';
import Notification from '@/Shared/Header/Notification.vue';

export default {
  components: {
    Profile,
    Notification,
  },

  props: {
    activeItem: '',
  },

  data() {
    return {
      moreMenuExpanded: false,
    };
  },

  methods: {
    logout() {
      this.$inertia.post('/logout', {});
    },
  },
};
</script>