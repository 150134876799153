
<layout>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <!-- Page Header: start -->
        <div class="md:flex md:items-center md:justify-between">
          <div v-if="false" class="flex-1 min-w-0">
            <div class="-ml-2 -mt-2 flex flex-col sm:flex-row flex-wrap items-baseline">
              <h2 class="ml-2 mt-2 text-2xl leading-7 font-light text-gray-900">
                Neue Social Graphic anlegen
              </h2>
              <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
                Vorlage &quot;{{ template.name }}&quot;
              </p>
            </div>
          </div>
          <div class="mt-4 flex md:mt-0 md:ml-4">

            <span v-if="false" class="sm:ml-3 shadow-sm rounded-md">
              <Link :href="route('social-graphics.index')"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-gray-500 hover:text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-400 active:bg-gray-400 transition duration-150 ease-in-out">
              Übersicht</Link>
            </span>
          </div>
        </div>
        <!-- Page Header: end -->

        <horizontal-divider />

        <!-- <form @submit.prevent="submit" novalidate> -->
        <form novalidate>
          <div class="min-h-[700px]">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1 px-4 sm:px-0 relative">
                <spinner :busy="svgBusy"></spinner>
                <div class="template shadow-md absolute w-min" v-html="svg"></div>
              </div>
              <div class="mt-5 md:mt-0 md:col-span-2">

                <!-- splitbutton: start -->
                <!-- <div class="relative inline-block">
                  <div class="flex shadow-sm">
                    <button
                     :disabled="busy" @click="store"
                      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-l-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green hover:text-white focus:outline-none  border-r border-r-gray-200"
                      onclick="alert('Default action')">
                      Speichern
                    </button>

                    <button
                    type="button"
                     :disabled="busy" @click="toggleButtonMenu"
                      class="inline-flex items-center px-2 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green hover:text-white focus:outline-none  "
                      >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>

                  <div v-if="showButtonMenu"
                  id="text123"
                    class="absolute right-0 mt-1 w-fit bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
                      <a href="#" class="whitespace-nowrap block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        onclick="alert('Secondary action 1')">
                        Als PNG mit Transparenz Speichern
                      </a>
                    </div>
                  </div>
                </div> -->
                <!-- splitbutton: end -->

                <button :disabled="busy" @click="store"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brandhelfer-green shadow-sm hover:bg-brandhelfer-green hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandhelfer-green">
                  Speichern
                </button>

                <horizontal-divider />

                <div class="flex flex-row">
                  <div class="w-1/5">
                    <div v-if="hasBackgroundImage">
                      <i @click="activeSection = 'background-image'"
                        class="fa fa-fw fa-lg fa-image mb-4 cursor-pointer hover:text-brandhelfer-green" :class="activeSection == 'background-image'
                          ? 'text-brandhelfer-green'
                          : ''
                          "></i>
                    </div>

                    <div v-if="hasBackgroundColors">
                      <i @click="activeSection = 'background-color'"
                        class="fa fa-fw fa-lg fa-paint-brush mb-4 cursor-pointer hover:text-brandhelfer-green" :class="activeSection == 'background-color'
                          ? 'text-brandhelfer-green'
                          : ''
                          "></i>
                    </div>

                    <div v-if="hasLayers">
                      <i @click="activeSection = 'layer'"
                        class="fa fa-fw fa-lg fa-object-ungroup mb-4 cursor-pointer hover:text-brandhelfer-green"
                        :class="activeSection == 'layer'
                          ? 'text-brandhelfer-green'
                          : ''
                          "></i>
                    </div>

                    <div v-if="hasTexts">
                      <i @click="activeSection = 'text'"
                        class="fa fa-fw fa-lg fa-font mb-4 cursor-pointer hover:text-brandhelfer-green" :class="activeSection == 'text'
                          ? 'text-brandhelfer-green'
                          : ''
                          "></i>
                    </div>

                    <div v-if="hasExtras">
                      <i @click="activeSection = 'extras'"
                        class="fa fa-fw fa-lg fa-th-large mb-4 cursor-pointer hover:text-brandhelfer-green" :class="activeSection == 'extras'
                          ? 'text-brandhelfer-green'
                          : ''
                          "></i>
                    </div>
                  </div>

                  <div class="flex-grow">
                    <!-- Background Image -->
                    <div v-show="hasBackgroundImage &&
                      activeSection == 'background-image'
                      " class="flex flex-row w-full">
                      <background-image :backgroundImage="backgroundImage" @imageUploading="backgroundImageUploading"
                        @imageUploaded="backgroundImageUploaded" @statusToggled="backgroundImageStatusToggled">
                      </background-image>
                    </div>

                    <div class="flex-grow">
                      <!-- Background Image -->
                      <div v-show="hasBackgroundImage &&
                        usesBackgroundImageLibrary &&
                        activeSection == 'background-image'
                        " class="flex flex-row w-full">
                        <background-image-library :template="template" @imageSelected="backgroundImageSelected">
                        </background-image-library>
                      </div>
                    </div>


                    <!-- Layer -->
                    <transition name="fade">
                      <div v-show="hasLayers &&
                        activeSection == 'layer'
                        ">
                        <div class="flex flex-row w-full mb-4" v-for="layer in layers" :key="layer.id">
                          <layer :template="template" :layer="layer" @layerSelected="layerSelected"
                            @statusToggled="layerStatusToggled" :enabled="form.layerStatus[layer.id] || false">
                          </layer>
                        </div>
                      </div>
                    </transition>


                    <!-- Background Color -->
                    <transition name="fade">
                      <div v-show="hasBackgroundColors &&
                        activeSection == 'background-color'
                        ">
                        <div class="flex flex-row w-full mb-4" v-for="backgroundColor in backgroundColors"
                          :key="backgroundColor.id">
                          <background-color :backgroundColor="backgroundColor"
                            @colorSelected="backgroundColorSelected"
                            @colorStatusToggled="backgroundColorStatusToggled"></background-color>
                        </div>
                      </div>
                    </transition>

                    <!-- Text -->
                    <transition name="fade">
                      <div v-show="hasTexts && activeSection == 'text'" class="w-full">
                        <div>Texte</div>

                        <div class="mt-6">
                          <div v-for="(text, idx) in texts" :key="`text${idx}`" class="flex flex-row w-full mb-6">
                            <textinput v-if="text.type == 'textinput'" :text="text" @textEntered="textEntered"
                              @textColorChanged="textColorChanged" @textStatusToggled="textStatusToggled"></textinput>

                            <textbox v-if="text.type == 'textbox'" :text="text" @textEntered="textboxTextEntered"
                              @textColorChanged="textColorChanged" @textStatusToggled="textStatusToggled">
                            </textbox>

                            <selection v-if="text.type == 'selection'" :select="text" @optionSelected="optionSelected"
                              @selectColorChanged="selectColorChanged" @selectStatusToggled="selectStatusToggled">
                            </selection>
                          </div>
                        </div>
                      </div>
                    </transition>





                    <!-- Extras -->
                    <transition name="fade">
                      <div v-show="hasExtras && activeSection == 'extras'" class="w-full">
                        <div class="mt-6">
                          <div v-for="(extra, idx) in extras" :key="`extra${idx}`" class="flex flex-row w-full mb-6">
                            <color v-if="extra.type == 'color'" :color="extra" @colorChanged="extraColorChanged"
                              @statusToggled="extraColorStatusToggled"></color>

                            <display v-if="extra.type == 'display'" :display="extra"
                              @statusToggled="extraDisplayStatusToggled" :enabled="extra.enabled ? true : false">
                            </display>

                            <display-group v-if="extra.type == 'displayGroup'" :display="extra"
                              @statusToggled="extraDisplayStatusToggled"></display-group>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <pending-modal v-if="busy"></pending-modal>
</layout>
